.ih-empty-table {
  margin: 0 15px;
  background: #f5f5f5;
  border: 1px solid #EAEAEA;

  .flexbox & {
    min-height: 400px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .no-flexbox & {
    padding: 100px 0;
  }

  h2,
  h3 {
    color: $gray-light;
  }

  .ih-empty-table__icons {
    margin-top: 20px;
  }

  .ih-empty-table__icon {
    margin-right: 10px;
    display: inline-block;
  }

  .scanner-icon {
    width: 34px;
    height: 41px;
  }

  .fa-keyboard-o {
    position: relative;
    top: -8px;
  }

  // <div class="ih-empty-table__icons">
  //
  //   <div class="ih-empty-table__icon">
  //     <svg class="scanner-icon">  <use xlink:href="#icon-scanner" /></svg>
  //   </div>
  //
  //   <div class="ih-empty-table__icon">
  //     <i class="fa fa-keyboard-o fa-2x" aria-hidden="true"></i>
  //   </div>

}
