.popout-box-wrapper {
  display: inline-block;
  position: relative;
  height: 30px;

  &.popout-box-wrapper__no-styling {
    display: inline;
    height: auto;

    .popout-button {
      display: inline;
      background: transparent;
      border: none;
      color: inherit;
      padding: 0;
      height: auto;

      &::after {
        content: '';
      }

      &.active {
        background: transparent;
        color: inherit;
      }
    }
  }
}

.popout-button {
  position: relative;
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
  @include user-select(none);
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
  padding: 3px 25px 3px 10px;
  height: 30px;
  z-index: 1;

  &::after {
    content: "\f107";
    font-family: "FontAwesome";
    position: absolute;
    right: 9px;
    top: 5px;
  }

  &.active {
    background: #FFF;
    border-color: $color-accent;
    color: $color-secondary;
  }

  &--no-icon {
    padding-right: 10px;

    &::after {
      content: '';
    }
  }
}

.popout-box {
  display: none;
  position: absolute;
  top: 30px; // Typical height of the button
  left: 0;

  background: #FFF;
  border: 1px solid $btn-default-border;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
  text-align: left;
  width: 500px;
  z-index: 101;

  padding: 15px 15px 20px;


  &.right-align {
    left: auto;
    right: 0;

    table & {
      top: 17px;
      right: 13px;
    }

    &.dispense {
      table & {
        top: 0;
        right: 38px;
      }
    }
  }

  table & {
    top: 17px;
    left: 13px;
    width: 300px;

    .btn-accent,
    .btn-secondary,
    .btn-primary {
      color: #FFF !important;
    }
  }

  label,
  .fa {
    color: $text-color;
  }
}

table .popout-box {
  .col-xs-6,
  .col-xs-8,
  .col-xs-12 {
    padding-left: 0;
  }

  label {
    display: block;
  }
}

.popout-box__title {
  display: block;
  margin-bottom: 5px;
  color: $color-secondary;
  font-family: $ff-sans-bold;
}

.form-inline input.popout-box__input,
.form-inline select.popout-box__input {
  width: 100%
}

.popout-box__content {
  display: block;
  margin-top: 10px;

  &.filter-engaged {
    color: lighten($text-color,50%);
  }

  p {
    margin: 0;
    border-bottom: 1px solid #cccccc;
    line-height: 1.1;
    padding: 7px 10px;

    &:hover {
      cursor: pointer !important;
      background-color: $color-secondary;
      color: white;
    }
  }
}


.icon-location {
  width: 14px;
  height: 12.6px;
  position: relative;
  top: 3px;
}

.icon-right-arrow {
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
}

.icon-tag {
  width: 19px;
  height: 14px;
  position: relative;
  top: 3px;
}

.icon-patient {
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
}

.icon-doctor {
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
}

.icon-nope {
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
}

.icon-rx {
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
}

.icon-undo {
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
}

.icon-close {
  width: 15px;
  height: 15px;
  position: relative;
  top: 3px;
}

.icon-shipped {
  width: 18px;
  height: 14px;
  position: relative;
  top: 3px;

  &--large {
    width: 31px;
    height: 20px;
    top: 5px;
  }
}

.icon-filefolder {
  width: 17px;
  height: 15px;
  position: relative;
  top: 3px;
}

.icon-print {
  width: 12px;
  height: 15px;
  position: relative;
  top: 3px;
}

.icon-shield-check {
  width: 15px;
  height: 15px;
  position: relative;
  top: 3px;
}

.page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}


// Specific overrides
.table-inventory-product.table-hover > tbody > tr:hover a {
  color: $text-color;
}

.table-inventory-product .popout-box-wrapper .popout-button {
  visibility: hidden;
}

.table-inventory-product tr:hover .popout-box-wrapper .popout-button {
  visibility: visible;
}

.table-inventory-product .popout-box-wrapper .popout-button--visible-in-table {
  visibility: visible;
}

.table-inventory-product > tbody > tr.popout-box-active td {
  color: #FFF;

  .next-line {
    color: #FFF;
  }
}
