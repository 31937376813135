.table-product-order-history {
  tbody {
    font-size: .9em;
  }
  td table {
    td:nth-child(2) {
      padding-left: $padding-small;
    }
    td {
      border-right: none;
    }
  }
  td.vertically-center {
    vertical-align: middle;
  }

  .popover {
    color: $text-color;
    font-size: .9em;
    max-width: 200px;
  }
}

.product-order-history-clinics {
  + .chosen-container-multi .chosen-choices {
    height: 25px !important;
    .search-choice {
      font-size: .7em;
      padding-bottom: 0;
    }
  }
  + .chosen-container-multi.chosen-container-active .chosen-choices {
    height: auto !important;
  }
}

.choose-clinics-filter {
  border-radius: 4px;
  font-size: 14px;
  padding: 2px 10px 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  line-height: 1.428571429;
  height: 24px;
}