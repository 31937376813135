.margin-top-xsmall {  margin-top: $padding-xsmall;  }
.margin-top-small {  margin-top: $padding-small;  }
.margin-top-medium {  margin-top: $padding-medium;  }
.margin-top-large {  margin-top: $padding-large;  }
.margin-top-xlarge {  margin-top: $padding-xlarge;  }
.margin-top-xxlarge {  margin-top: $padding-xxlarge;  }

.margin-right-xsmall {  margin-right: $padding-xsmall;  }
.margin-right-small {  margin-right: $padding-small;  }
.margin-right-medium {  margin-right: $padding-medium;  }
.margin-right-large {  margin-right: $padding-large;  }
.margin-right-xlarge {  margin-right: $padding-xlarge;  }
.margin-right-xxlarge {  margin-right: $padding-xxlarge;  }

.margin-bottom-xsmall {  margin-bottom: $padding-xsmall;  }
.margin-bottom-small {  margin-bottom: $padding-small;  }
.margin-bottom-medium {  margin-bottom: $padding-medium;  }
.margin-bottom-large {  margin-bottom: $padding-large;  }
.margin-bottom-xlarge {  margin-bottom: $padding-xlarge;  }
.margin-bottom-xxlarge {  margin-bottom: $padding-xxlarge;  }

.margin-left-xsmall {  margin-left: $padding-xsmall;  }
.margin-left-small {  margin-left: $padding-small;  }
.margin-left-medium {  margin-left: $padding-medium;  }
.margin-left-large {  margin-left: $padding-large;  }
.margin-left-xlarge {  margin-left: $padding-xlarge;  }
.margin-left-xxlarge {  margin-left: $padding-xxlarge;  }

.margin-top-null {  margin-top: 0; }
.margin-right-null {  margin-right: 0; }
.margin-bottom-null {  margin-bottom: 0; }
.margin-left-null {  margin-left: 0; }


.margin-top-xsmall--important {  margin-top: $padding-xsmall !important;  }
.margin-top-small--important {  margin-top: $padding-small !important;  }
.margin-top-medium--important {  margin-top: $padding-medium !important;  }
.margin-top-large--important {  margin-top: $padding-large !important;  }
.margin-top-xlarge--important {  margin-top: $padding-xlarge !important;  }
.margin-top-xxlarge--important {  margin-top: $padding-xxlarge !important;  }

.margin-right-xsmall--important {  margin-right: $padding-xsmall !important;  }
.margin-right-small--important {  margin-right: $padding-small !important;  }
.margin-right-medium--important {  margin-right: $padding-medium !important;  }
.margin-right-large--important {  margin-right: $padding-large !important;  }
.margin-right-xlarge--important {  margin-right: $padding-xlarge !important;  }
.margin-right-xxlarge--important {  margin-right: $padding-xxlarge !important;  }

.margin-bottom-xsmall--important {  margin-bottom: $padding-xsmall !important;  }
.margin-bottom-small--important {  margin-bottom: $padding-small !important;  }
.margin-bottom-medium--important {  margin-bottom: $padding-medium !important;  }
.margin-bottom-large--important {  margin-bottom: $padding-large !important;  }
.margin-bottom-xlarge--important {  margin-bottom: $padding-xlarge !important;  }
.margin-bottom-xxlarge--important {  margin-bottom: $padding-xxlarge !important;  }

.margin-left-xsmall--important {  margin-left: $padding-xsmall !important;  }
.margin-left-small--important {  margin-left: $padding-small !important;  }
.margin-left-medium--important {  margin-left: $padding-medium !important;  }
.margin-left-large--important {  margin-left: $padding-large !important;  }
.margin-left-xlarge--important {  margin-left: $padding-xlarge !important;  }
.margin-left-xxlarge--important {  margin-left: $padding-xxlarge !important;  }

.margin-top-null--important {  margin-top: 0 !important; }
.margin-right-null--important {  margin-right: 0 !important; }
.margin-bottom-null--important {  margin-bottom: 0 !important; }
.margin-left-null--important {  margin-left: 0 !important; }

.border-bottom--gray {
  border-bottom: 1px solid $gray-lighter;
}

.border-top--gray {
  border-top: 1px solid $gray-lighter;
}

.margin-bottom-null-important {
  margin-bottom: 0 !important;
}
