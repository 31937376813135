.reports {
  margin: 4px -15px $padding-small;
  border-bottom: 2px solid $gray-lighter;

  + .utility-header {
    padding-top: 0;
  }

  &--toggle-to-show {
    float: right;
    font-size: .9em;
    margin-top: $padding-xsmall;
    padding-bottom: $padding-xsmall;

    &[aria-expanded="true"] {
      display: none;
    }
  }

  &--revealed[aria-expanded="true"] {
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: $padding-xsmall;
    position: relative;

    &:before {
      @include gradient-vertical($start-color: $gray-lighter, $end-color: #FFF, $start-percent: 0%, $end-percent: 100%);
      content: '';
      position: absolute;
      top: 28px;
      width: 100%;
      height: 15px;
    }
    &:after {
       @include gradient-vertical($start-color: #FFF, $end-color: $gray-lighter, $start-percent: 0%, $end-percent: 100%);
       content: '';
       position: absolute;
       bottom: 0;
       width: 100%;
       height: 15px;
     }
  }

  //  Header above the charts
  &--header {
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: $padding-small;
    text-align: right;
    font-size: .9em;
    position: relative;
    z-index: 100;
    padding: $padding-xsmall 15px;

    &--headline {
      margin-right: $padding-small;
      font-size: 1.2em;
    }
  }


  // Charts
  &--charts {
    &--chart {
      text-align: center;
    }

    &--headline {
      font-family: $ff-sans-bold;
      margin-top: $padding-small;
      margin-bottom: $padding-small;
      text-transform: uppercase;
    }

    &--circle {
      border-radius: 100%;
      color: #FFF;
      display: inline-block;
      margin-bottom: $padding-small;
      width: 130px;
      height: 130px;

      &--count {
        display: inline-block;
        margin-top: 30px;
        font-size: 4em;
        line-height: 1;

        &.two-characters {
          margin-top: 25px;
          font-size: 5em;
        }

        &.one-character {
          margin-top: 13px;
          font-size: 5em;
        }
      }

      &--text {
        font-size: .95em;
        text-transform: uppercase;
      }
    }
  }

}
