b,
strong {
  font-weight: bold;
}

.color--black-25 {
  color: #b7b8b7;
}

body {
  p {
    font-size: 18px;
  }
}
.list-group {
  font-size: 1.125em;
}
