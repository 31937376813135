.dynamic-content-wrapper {
  position: relative;
}

.help-button-wrapper {
  position: fixed;
  bottom: 75px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;

  .popover {
    position: fixed;
  }
  .popover-content {
    font-size: .85em;
  }
}
